// import {Link, Container, List,ListItem,Typography} from '@material-ui/core';
// import Box from '@material-ui/core/Box';
import {Grid} from '@material-ui/core';
import {createMuiTheme, ThemeProvider, responsiveFontSizes} from '@material-ui/core/styles'
import Content from "./Content.js";
import './App.css';

function App() {

  let theme = createMuiTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#004d40',
        dark: '#002884',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#00bcd4',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
  });
  theme = responsiveFontSizes(theme);
  return (
      <div>
        <ThemeProvider theme = {theme}>
          <Grid container direction="column" style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary" >
            <Content/>
          </Grid>
        </ThemeProvider>
{/*     
        <Box display="flex" justifyContent="center" m={0} p={0} >
        <Box p={1}>
          <Typography variant="h2">valurauta.com</Typography>
 */}

        <df-messenger
          intent="WELCOME"
          chat-title="Cast Iron Helper"
          agent-id="4913d7c5-ccc0-45c5-88eb-9263557f836a"
          language-code="en"
          ></df-messenger>
          
          {/* </Box>
                  </Box>
      
                  <Box display="flex" justifyContent="center" m={0} p={0}>
        <Box p={1}>
                 */}
          {/* <div className="App-list"> */}
            
          {/* <List>
            <ListItem>
              <Typography variant="h3">What is this?</Typography>
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1">Demo project for Haaga-Helia Advanced AI course about chatbots by <Link href="https://www.linkedin.com/in/aarnikarjalainen/" target="_blank"><b>Aarni K</b></Link> </Typography>
            </ListItem>
            <ListItem>
            <Typography variant="h3">How it's made?</Typography>
            </ListItem>
              <ListItem>
              <Typography variant="subtitle1">Chatbot is done with Google's Dialogflow platform using the Dialogflow Messenger integration. 
                Most of the tricks are based on one long webhook script without any external databases.
                Website is done with create-react-app template using ReactJS & Material-UI. Hosting by Netlifly.</Typography>
                </ListItem>
              <ListItem>
              <Typography variant="h3">How it works?</Typography>
              </ListItem>
              <ListItem>
              <Typography variant="subtitle1">After formal greeting, the chatbot should handle </Typography>
              </ListItem>
              
              <List><b>
                <ListItem>What, &nbsp; Where &nbsp;&&nbsp; How</ListItem>
                </b>
                </List>
                <ListItem>
                  <Typography variant="subtitle1">questions related to <b>cast iron</b></Typography>
              </ListItem>
              <ListItem>
              <b>Seasoning, &nbsp; Rust Removal &nbsp;&&nbsp; Restoration</b>
              </ListItem>
              <ListItem>
              <Typography variant="subtitle1">Try it out, engage with the bot and ask for example; <b><i>'what is cast iron'</i></b></Typography>
              </ListItem>
            </List>
             */}
          {/* </div> */}
            {/* </Box>
          </Box>
           */}
    </div>
  );
}

export default App;
