import React, { Component } from 'react';
import {Grid, Paper, Typography, Link} from '@material-ui/core';
import './App.css';

class Content extends Component {
    
    render() {

          
        return (
            <div>
                <Grid container spacing={1} direction="column" color="primary"> 
                <Grid item xs={12} container color="primary">
                    <Grid item xs={2} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary"/>
                    <Grid item xs={8} style={{ display:"flex", justifyContent:"center", alignItems:"center"}} >
                        <Paper elevation={0}>
                            <Typography color="primary" variant="h3">www.valurauta.com</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={2} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary"/>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={2} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary"/>
                    <Grid item xs={8} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary">
                        <Paper elevation={0}>
                            <Typography variant="h4">What is this?</Typography>
                            <Typography variant="body1">Prototype of a chatbot from Haaga-Helia Advanced AI course by <Link href="https://www.linkedin.com/in/aarnikarjalainen/" target="_blank"> Aarni K</Link></Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={2} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary"/>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={2} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary"/>
                        <Grid item xs={8} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                        <Paper elevation={0}>
                            <Typography variant="h4">How it's made?</Typography>
                            <Typography variant="body1">Chatbot is done with Google's Dialogflow platform using the Dialogflow Messenger integration. Most of the tricks are based on one long webhook script without any external databases. Website is done with create-react-app template using ReactJS & Material-UI. Hosting by Netlify.</Typography>
                        </Paper>
                        <Grid item xs={2} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary"/>
                    </Grid>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={2} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary"/>

                    <Grid item xs={8} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}}>
                        <Paper elevation={0}>
                            <Typography variant="h4">How it works?</Typography>
                            <Typography variant="body1">After a formal greeting, chatbot should be able to handle how, where and what questions regarding cast iron seasoning, rust removal and restoration.</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={2} style={{ display:"flex", justifyContent:"flex-start", alignItems:"center"}} color="primary"/>
                    
                </Grid>
                </Grid>
            </div>
        );
    }
}

export default Content;